export const REGISTER_INIT = { url: "/members/register/init", method: "POST" }
export const REGISTER_COMPLETE = {
    url: "/members/register/complete",
    method: "PATCH",
}
export const REGISTER_REFRESH = {
    url: "/members/register/refresh",
    method: "POST",
}
export const REGISTER_ME = { url: "/members/register/me", method: "GET" }
export const MEMBER_ME = { url: "/members/member/me", method: "GET" }

export const UPDATE_FIRSTNAME = {
    url: "/members/register/first-name",
    method: "PATCH",
}
export const UPDATE_LASTNAME = {
    url: "/members/register/last-name",
    method: "PATCH",
}
export const UPDATE_EMAIL = { url: "/members/register/email", method: "PATCH" }
export const UPDATE_ADDRESS = {
    url: "/members/register/address",
    method: "PATCH",
}
export const UPDATE_PHONENUMBER = {
    url: "/members/register/phone-number",
    method: "PATCH",
}
export const UPDATE_DATEOFBIRTH = {
    url: "/members/register/date-of-birth",
    method: "PATCH",
}
export const UPDATE_PASSWORD = {
    url: "/members/register/password",
    method: "PATCH",
}
export const GET_ADDRESS_BY_POSTCODE = {
    url: "/address/find-by-postcode",
    method: "GET",
}
export const REGISTER_VERIFY_BY_TOKEN = {
    url: "/members/verify/email",
    method: "PATCH",
}
export const REGISTER_VERIFY_BY_MANUAL_CODE = {
    url: "/members/verify/email-code",
    method: "PATCH",
}
export const REGISTER_VERIFY_RESEND = {
    url: "/members/verify/resend-email",
    method: "POST",
}
export const MEMBER_LOGIN = {
    url: "/members/member/auth",
    method: "POST",
}
export const REGISTRATION_COUNTRIES = {
    url: "/address/countries",
    method: "GET",
}
export const BEGIN_KYC_INIT = {
    url: "/members/confirm/init",
    method: "POST",
}
export const MEMBER_REFRESH = {
    url: "/members/member/refresh",
    method: "POST",
}

export const KYC_CONFIRM = {
    url: "/members/confirm/respond",
    method: "POST",
}
export const SCHEDULE_SEARCH = {
    url: "/assets/search/search",
    method: "POST",
}

export const SEARCH_STATUS = {
    url: "/assets/search/status",
    method: "GET",
}
export const SEARCH_RESULTS = {
    url: "/assets/search/results-current",
    method: "GET",
}
export const SEARCH_RESULTS_CLAIMED = {
    url: "/assets/search/results-noncurrent",
    method: "GET",
}
export const SEARCH_RESULT_DETAILS = {
    url: "/assets/search/result/",
    method: "GET",
}
export const CLAIM_RESULT = {
    url: "/assets/search/claim/",
    method: "PATCH",
}
export const UNCLAIM_RESULT = {
    url: "/assets/search/unclaim/",
    method: "PATCH",
}
export const LOST_PASSWORD_REQUEST = {
    url: "/members/member/lost-password",
    method: "POST",
}
export const CHECK_PASSWORD_RESET_TOKEN = {
    url: "/members/member/password-reset-check-token",
    method: "POST",
}
export const PASSWORD_RESET_BY_TOKEN = {
    url: "/members/member/password-reset",
    method: "PATCH",
}
export const PASSWORD_RESET_BY_MANUAL_CODE = {
    url: "/members/member/password-reset-code",
    method: "PATCH",
}
export const VERIFY_MEMBER_UPDATE_EMAIL = {
    url: "/members/verify/update-email",
    method: "PATCH",
}

/**
 * End of previous address endpoints
 */

/**
 * Email claim results
 */

export const ASSETS_SEARCH_EMAIL_RESULT = {
    url: "/assets/search/email-result/",
    method: "POST",
}

export const GET_ASSETS_SEARCH_RESULTS_SUMMARY = {
    url: "/assets/search/results-current-summary",
    method: "GET",
}
export const GET_ASSETS_SEARCH_RESULTS_DEFAULT_SUMMARY = {
    url: "/assets/search/results-summary",
    method: "GET",
}

/**
 * NEW CONFIRM ADDRESS ROUTES
 */
export const MEMBERS_CONFIRM_PREVIOUS_ADDRESSES = {
    url: "/members/confirm/previous-addresses",
    method: "GET",
}

export const MEMBERS_CONFIRM_ACCEPT_ADDRESS = {
    url: "/members/confirm/address/accept/",
    method: "POST",
}
export const MEMBERS_CONFIRM_REJECT_ADDRESS = {
    url: "/members/confirm/address/reject/",
    method: "POST",
}
export const MEMBERS_CONFIRM_ADD_ADDRESS = {
    url: "/members/address",
    method: "POST",
}

/**
 * Member Previous Address endpoints
 */
export const MEMBERS_MEMBER_PREVIOUS_ADDRESSES = {
    url: "/members/address/previous",
    method: "GET",
}
export const MEMBERS_MEMBER_ADDRESSES = {
    url: "/members/address",
    method: "GET",
}
export const MEMBERS_MEMBER_ADDRESS_EDIT = {
    url: "/members/address/",
    method: "PATCH",
}
export const MEMBERS_MEMBER_ADDRESS_DELETE = {
    url: "/members/address/",
    method: "DELETE",
}
export const MEMBERS_MEMBER_ADDRESSES_GET = {
    url: "/members/address",
    method: "GET",
}
export const MEMBERS_MEMBER_ADDRESS_GET = {
    url: "/members/address/",
    method: "GET",
}
export const MEMBERS_MEMBER_REJECTED_ADDRESS = {
    url: "/members/member/rejectedaddress/",
    method: "GET",
}

/** Registration verify captcha */
export const MEMBERS_REGISTER_VERIFY_CAPTCHA = {
    url: "/members/register/captcha",
    method: "POST",
}

/**
 * New Registration Endpoints
 */

export const MEMBERS_REGISTER_MIDDLE_NAMES = {
    url: "/members/register/middle-names",
    method: "PATCH",
}
export const MEMBERS_REGISTER_PREFERRED_NAME = {
    url: "/members/register/preferred-name",
    method: "PATCH",
}

/**
 * My Account endpoints
 */
export const MEMBERS_ACCOUNT_FIRST_NAME_EDIT = {
    url: "/members/account/first-name",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_MIDDLE_NAMES_EDIT = {
    url: "/members/account/middle-names",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_LAST_NAME_EDIT = {
    url: "/members/account/last-name",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_PREFERRED_NAME_EDIT = {
    url: "/members/account/preferred-name",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_DATE_OF_BIRTH_EDIT = {
    url: "/members/account/date-of-birth",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_PHONE_NUMBER_EDIT = {
    url: "/members/account/phone-number",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_ADDRESS_EDIT = {
    url: "/members/account/address",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_EMAIL_CHANGE_REQUEST = {
    url: "/members/account/email",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_VERIFY_EMAIL_EDIT = {
    url: "/members/account/verify-email",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_VERIFY_EMAIL_BY_CODE_EDIT = {
    url: "/members/account/verify-email-code",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_PASSWORD_EDIT = {
    url: "/members/account/password",
    method: "PATCH",
}

/**
 * Previous Names Endpoints
 */

export const MEMBERS_NAME_TYPES_GET = {
    url: "/members/name/types",
    method: "GET",
}
export const MEMBERS_NAME_NAME_GET = {
    url: "/members/name",
    method: "GET",
}
export const MEMBERS_NAME_PREVIOUS_NAME_GET = previousNameId => ({
    url: `/members/name/${previousNameId}`,
    method: "GET",
})
export const MEMBERS_NAME_PREVIOUS_NAME_ADD = {
    url: "/members/name",
    method: "POST",
}
export const MEMBERS_NAME_PREVIOUS_NAME_EDIT = {
    url: "/members/name",
    method: "PATCH",
}
export const MEMBERS_NAME_PREVIOUS_NAME_DELETE = {
    url: "/members/name/",
    method: "DELETE",
}

/**
 * Account recovery
 */
export const MEMBERS_MEMBER_RECOVER_OPTIONS = {
    url: "/members/member/recover/options",
    method: "POST",
}
export const MEMBERS_MEMBER_RECOVER_SEND_SMS = {
    url: "/members/member/recover/send-sms",
    method: "POST",
}
export const MEMBERS_MEMBER_RECOVER_SMS = {
    url: "/members/member/recover/sms",
    method: "POST",
}
/**
 * MEMBERS ADDRESS REVIEWED
 */
export const MEMBERS_ADDRESS_REVIEWED = {
    url: "/members/address/reviewed",
    method: "POST",
}
/**
 * MEMBERS ACCOUNT PERSONAL DETAILS
 */
export const MEMBERS_ACCOUNT_PERSONAL_DETAILS = {
    url: "/members/account/personal-details",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_NAME_DOCUMENT_UPLOAD = {
    url: "/members/name/upload",
    method: "POST",
}
export const MEMBERS_ACCOUNT_NAME_DOWNLOAD_DOCUMENT = previousNameId => ({
    url: `/members/name/${previousNameId}/download`,
    method: "GET",
})

export const MEMBERS_MEMBER_ADDRESSES_ADD_NEW = {
    url: "/members/address",
    method: "POST",
}

export const MEMBERS_MEMBER_ADDRESSES_EDIT = addressId => ({
    url: `/members/address/${addressId}`,
    method: "PATCH",
})

export const MEMBERS_CONFIRM_BANK_INITIALISE = {
    url: "/members/confirm/bank/init",
    method: "POST",
}
export const MEMBERS_CONFIRM_BANK_CALLBACK = {
    url: "/external/idverification/bank/callback",
    method: "POST",
}
export const MEMBERS_CONFIRM_BANK_SCHEDULE = {
    url: "/members/confirm/bank/schedule",
    method: "POST",
}
export const MEMBERS_CONFIRM_BANK_STATUS = verificationId => ({
    url: `/members/confirm/bank/status/${verificationId}`,
    method: "GET",
})

/**
 * New routes 29/03/22
 */

export const MEMBERS_ACCOUNT_UPDATE_NATIONAL_INSURANCE = {
    url: "/members/account/ni-number",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_PROFILE_CONFIRM_ADDRESSES = {
    url: "/members/account/profile/addresses",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_PROFILE_CONFIRM_NAMES = {
    url: "/members/account/profile/names",
    method: "PATCH",
}
export const MEMBERS_ACCOUNT_PROFILE_NI_NUMBER = {
    url: "/members/account/ni-number",
    method: "PATCH",
}

export const SEARCH_RESULT_DETAILS_MESSAGING = matchId => ({
    url: `/assets/search/result/${matchId}/register`,
    method: "GET",
})
export const MEMBERS_REGISTER_VALIDATE = {
    url: "/members/register/validate",
    method: "GET",
}
export const MEMBERS_SEARCH_RESULTS_CONNECTED = {
    url: "/assets/search/results-connected",
    method: "GET",
}
export const MEMBERS_SEARCH_RESULTS_CONNECTED_PERSON = personId => ({
    url: `/assets/search/results-connected/${personId}`,
    method: "GET",
})
export const MEMBERS_SEARCH_RESULTS_NONCURRENT_PERSON = personId => ({
    url: `/assets/search/results-noncurrent/${personId}`,
    method: "GET",
})
export const MEMBERS_ACCOUNT_DELETE = {
    url: "/members/account/delete",
    method: "DELETE",
}
export const MEMBERS_GET_PARTNERS = {
    url: "/partners",
    method: "GET",
}
export const MEMBERS_REFER_PARTNER = partnerId => ({
    url: `/partners/${partnerId}`,
    method: "POST",
})
export const MEMBERS_GET_NOTIFICATIONS = {
    url: "/notifications",
    method: "GET",
}

/**
 * Third Party related endpoints
 */
export const MEMBERS_THIRD_PARTY_GET_TYPES = {
    url: "/members/third-party/types",
    method: "GET",
}
export const MEMBERS_THIRD_PARTY_GET_ALL = {
    url: "/members/third-party",
    method: "GET",
}
export const MEMBERS_THIRD_PARTY_VIEW_INDIVIDUAL = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}`,
    method: "GET",
})
export const MEMBERS_THIRD_PARTY_GET_INDIVIDUAL_DOCUMENT = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}/download`,
    method: "GET",
})
export const MEMBERS_THIRD_PARTY_ADD_INDIVIUDAL = {
    url: "/members/third-party",
    method: "POST",
}
export const MEMBERS_THIRD_PARTY_ADD_INDIVIUDAL_DOCUMENT = {
    url: "/members/third-party/upload",
    method: "POST",
}
export const MEMBERS_THIRD_PARTY_DELETE_INDIVIDUAL = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}`,
    method: "DELETE",
})
export const MEMBERS_THIRD_PARTY_RESULTS_CURRENT = thirdPartyId => ({
    url: `/assets/search/results-current/${thirdPartyId}`,
    method: "GET",
})
export const MEMBERS_THIRD_PARTY_GET_INDIVIDUAL_ADDRESSES = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}/address`,
    method: "GET",
})
export const MEMBERS_THIRD_PARTY_ADD_INDIVIDUAL_ADDRESSES = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}/address`,
    method: "POST",
})
export const MEMBERS_THIRD_PARTY_GET_INDIVIDUAL_NAMES = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}/name`,
    method: "GET",
})
export const MEMBERS_THIRD_PARTY_ADD_INDIVIDUAL_NAMES = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}/name`,
    method: "POST",
})
export const MEMBERS_THIRD_PARTY_EDIT_NAME = thirdPartyId => ({
    url: `/members/third-party/${thirdPartyId}`,
    method: "PATCH",
})
export const MEMBERS_ACCOUNT_UPDATE_MARKETING_PREFERENCES = {
    url: `/members/account/marketing`,
    method: "PATCH",
}

export const MEMBERS_THIRD_PARTY_ADD_EDIT_NATIONAL_INSURANCE =
    thirdPartyId => ({
        url: `/members/third-party/${thirdPartyId}/ni-number`,
        method: "PATCH",
    })

export const PRE_REGISTER_INIT = {
    url: "/members/register/pre-register",
    method: "POST",
}

export const GET_CURRENT_FEEDBACK = {
    url: "/feedback",
    method: "GET",
}
export const POST_FEEDBACK = {
    url: "/feedback",
    method: "POST",
}

/**
 * DSAR Endpoints
 */

export const GET_ASSETS_DSAR_CONTACTS = {
    url: "/assets/dsar/contact",
    method: "GET",
}
export const GET_ASSETS_DSAR_CONTACT = contactId => ({
    url: `/assets/dsar/contact/${contactId}`,
    method: "GET",
})

export const GET_ASSETS_DSAR_CONTACT_DOWNLOAD = contactId => ({
    url: `/assets/dsar/contact/${contactId}/download`,
    method: "GET",
})
export const ASSETS_DSAR_CONTACT_SEND = contactId => ({
    url: `/assets/dsar/contact/${contactId}/send`,
    method: "POST",
})
